'use client';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import {
  fetchPermissions,
  getAccessTokenFromRefreshToken,
  isMySessionValid,
} from '@/services/users/userServices';
import { getCookie, setCookie } from 'cookies-next';
import { v4 as uuidv4 } from 'uuid';

import useWebSocketLogout from '@/hooks/useWebSocketLogout';

import { getDynamicEnvVarsInClient } from './envHelper';

const StoreTokens = () => {
  const searchParams = useSearchParams();
  const [dataFetched, setDataFetched] = useState(false);

  // Fetch or generate clientId
  const getClientId = () => {
    const clientIdFromCookie = getCookie('clientId');
    if (!clientIdFromCookie || clientIdFromCookie === 'null') {
      const newClientId = uuidv4();
      setCookie('clientId', newClientId);
      return newClientId;
    }
    return clientIdFromCookie;
  };

  const { NEXT_PUBLIC_CW_AUTH_URL } = getDynamicEnvVarsInClient();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = searchParams.get('accessToken') as string;
        const refreshToken = searchParams.get('refreshToken');
        const tenantName = searchParams.get('tenantName') as string;
        const tenantId = searchParams.get('tenantId') as string;
        const clientId = searchParams.get('clientId') as string;
        // Check if tenantId and clientId are present in the search params
        if (tenantId && clientId) {
          sessionStorage.setItem('dataFetched', 'false');
        }

        if (accessToken && refreshToken) {
          setCookie('accessToken', accessToken);
          setCookie('refreshToken', refreshToken);
          setCookie('tenantName', tenantName);
          setCookie('tenantId', tenantId);
          setCookie('clientId', clientId);
        }

        const storedAccessToken = getCookie('accessToken') as string;
        const storedRefreshToken = getCookie('refreshToken') as string;
        const storedTenantName = getCookie('tenantName') as string;
        const storedTenantId = getCookie('tenantId') as string;

        const sessionValid = await isMySessionValid(
          storedTenantId,
          storedTenantName,
          storedAccessToken,
          NEXT_PUBLIC_CW_AUTH_URL
        );

        if (!sessionValid) {
          const newTokens = await getAccessTokenFromRefreshToken(
            storedRefreshToken,
            NEXT_PUBLIC_CW_AUTH_URL
          );
          setCookie('accessToken', newTokens.accessToken);
          setCookie('refreshToken', newTokens.refreshToken);
        }
        if (
          storedAccessToken &&
          sessionStorage.getItem('dataFetched') === 'false'
        ) {
          const permission = await fetchPermissions(
            storedTenantId,
            storedTenantName,
            storedAccessToken,
            NEXT_PUBLIC_CW_AUTH_URL
          );
          const userPermissionsString = JSON.stringify(permission.data);
          setCookie('userPermissions', userPermissionsString);

          sessionStorage.setItem('dataFetched', 'true');
          setDataFetched(true);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchData();
  }, [searchParams]);

  useEffect(() => {
    const accessToken = getCookie('accessToken');
    const clientId = getClientId();

    if (typeof window !== 'undefined') {
      if (!accessToken || accessToken === 'null') {
        window.location.replace(
          `${window.location.origin}/auth/ui?tenantId=${process.env.NEXT_PUBLIC_TENANT_ID}&clientId=${clientId}&redirectURL=${window.location.origin}`
        );
      }
    }
  }, []);

  useEffect(() => {
    if (dataFetched) {
      window.location.replace('/');
    }
  }, [dataFetched]);

  const storedAccessToken = getCookie('accessToken') as string;
  const storedTenantName = getCookie('tenantName') as string;
  const storedTenantId = getCookie('tenantId') as string;

  useWebSocketLogout(
    storedAccessToken as string,
    storedTenantId as string,
    storedTenantName as string
  );

  return null;
};

export default StoreTokens;
