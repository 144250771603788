export const getBaseUrlInServer = async () => {
  // Server-side: Use Next.js headers to get host and protocol
  const { headers } = await import('next/headers');

  const headersList = headers();
  const host = headersList.get('host');
  const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';

  return `${protocol}://${host}`;
};

export const getBaseUrlInClient = () => {
  if (typeof window !== 'undefined') {
    // Client-side: Use window.location
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
  }
};
