import { getBaseUrlInClient, getBaseUrlInServer } from './baseUrlHelper';

export const getDynamicEnvVarsInServer = async () => {
  const baseUrl = await getBaseUrlInServer();

  return {
    NEXT_PUBLIC_CW_AUTH_URL: `${baseUrl}/auth/v1`,
    NEXT_PUBLIC_NEST_API_URL: `${baseUrl}/usm/v1`,
  };
};

export const getDynamicEnvVarsInClient = () => {
  const baseUrl = getBaseUrlInClient();

  return {
    NEXT_PUBLIC_CW_AUTH_URL: `${baseUrl}/auth/v1`,
    NEXT_PUBLIC_NEST_API_URL: `${baseUrl}/usm/v1`,
  };
};
