import { useState } from 'react';
import { getDynamicEnvVarsInClient } from '@/utils/envHelper';
import { deleteCookie } from 'cookies-next';

interface LogoutParams {
  xAuthTenantId: string;
  xAuthTenantName: string;
  authToken: string;
}

const useLogout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const logout = async ({
    xAuthTenantId,
    xAuthTenantName,
    authToken,
  }: LogoutParams) => {
    setIsLoading(true);
    setError(null);

    try {
      const { NEXT_PUBLIC_CW_AUTH_URL } = getDynamicEnvVarsInClient();
      // Call the logout endpoint
      const response = await fetch(`${NEXT_PUBLIC_CW_AUTH_URL}/logout`, {
        method: 'GET',
        headers: {
          'X-TENANT-ID': xAuthTenantId,
          'X-TENANT-NAME': xAuthTenantName,
          Authorization: `Bearer ${authToken}`,
        },
      });
      const res = await response.json();

      if (res.error === -1) {
        setIsLoading(false);
        // Delete cookies
        return true;
      } else {
        setError('Failed to logout');
        setIsLoading(false);
        return false;
      }
    } catch (err) {
      setError('Failed to logout');
      setIsLoading(false);
      return false;
    } finally {
      deleteCookie('accessToken');
      deleteCookie('refreshToken');
      deleteCookie('tenantName');
      deleteCookie('tenantId');
      deleteCookie('userPermissions');
      sessionStorage.removeItem('dataFetched');
      window.location.replace('/');
    }
  };

  return { logout, isLoading, error };
};

export default useLogout;
