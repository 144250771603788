'use client';

import React, { createContext, useEffect, useState } from 'react';
import type { IntlMessages } from 'global';

import enJson from '../../content/en.json';

export const LanguageContext = createContext<{
  locale: string;
  messages: IntlMessages | null;
}>({ locale: '', messages: null });

const getMsg = async (locale: string): Promise<IntlMessages> => {
  return (await import(`../../content/${locale}.json`)).default;
};

type LanguageProviderProps = {
  locale: string;
  children: React.ReactNode;
};

const LanguageProvider: React.FC<LanguageProviderProps> = ({
  locale,
  children,
}) => {
  const [messages, setMessages] = useState<IntlMessages | null>(enJson);
  useEffect(() => {
    const fetchData = async () => {
      const msg = await getMsg(locale);
      setMessages(msg);
    };

    fetchData();
  }, [locale]);

  return (
    <LanguageContext.Provider value={{ locale, messages }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
