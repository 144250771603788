import { useEffect } from 'react';
import { getDynamicEnvVarsInClient } from '@/utils/envHelper';
import io from 'socket.io-client';

import useLogout from './useLogout';

const useWebSocketLogout = (
  authToken: string,
  xAuthTenantId: string,
  xAuthTenantName: string
) => {
  const { logout } = useLogout();
  const { NEXT_PUBLIC_CW_AUTH_URL } = getDynamicEnvVarsInClient();
  useEffect(() => {
    const socket = io(NEXT_PUBLIC_CW_AUTH_URL as string, {
      auth: {
        token: authToken,
      },
      transports: ['websocket'], // Use WebSocket transport
      withCredentials: true,
    });

    socket.on('connect', () => {
      if (socket.connected) {
        console.log('Connected to WebSocket server');
      }
    });

    console.log({ socket });

    socket.on('forceLogout', () => {
      console.log('Received force logout event');
      logout({ xAuthTenantId, xAuthTenantName, authToken });
      socket.disconnect();
    });

    socket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
    });

    return () => {
      socket.disconnect();
    };
  }, [authToken, xAuthTenantId, xAuthTenantName]);
};

export default useWebSocketLogout;
